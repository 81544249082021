import { default as ArrowIcon } from '@ui-kit/icons/dist/icons/ArrowIcon';
import { useTranslations } from 'next-intl';

import styles from './MatchesTeaser.module.scss';

import UiText, { TextType } from '@components/Ui-text';

type Props = {
	numsOfSlides?: number;
	currentSlide?: number;
};

const MobileNavigation = ({ numsOfSlides, currentSlide }: Props) => {
	const t = useTranslations('translations');

	return (
		<div className={`${styles['matches-teaser__nav-button']}`}>
			<div className="matches-teaser__nav-button--prev">
				<ArrowIcon
					className={styles['matches-teaser__nav-button--prev']}
					width={20}
					height={20}
				/>
			</div>
			<UiText type={TextType.Subtitle_1}>{`${currentSlide + 1} ${t(
				'from',
			)} ${numsOfSlides}`}</UiText>
			<div className="matches-teaser__nav-button--next">
				<ArrowIcon
					className={styles['matches-teaser__nav-button--next']}
					width={20}
					height={20}
				/>
			</div>
		</div>
	);
};

export default MobileNavigation;
