import React, { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { A11y, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './MatchesTeaser.module.scss';

import { client } from '@apollo-client';
import MatchMiniatureTeaser from '@components/Match-miniature-teaser';
import UiButton, { ButtonType } from '@components/Ui-button';
import UiText, { TextType } from '@components/Ui-text';
import { StatMatch } from '@generated-graphql';
import teaserQueries from '@store/teaser/queries';
import {
	sendAnalytics,
	topLeaguesForLang,
	tranformLeaguesToDropdownItems,
} from '@utils/helpers';
import { chunkArray } from '@utils/tools';

import 'swiper/css';
import 'swiper/css/navigation';

import MobileNavigation from './MobileNavigation';

import { TRIBUNA_COM_URL } from '@utils/constants';

const DropdownMenu = dynamic(() => import('@components/Dropdown-menu'), {
	ssr: true,
});
interface IMatchesTeaserProps {
	popularMatches: StatMatch[];
	h1?: string;
}

export const MatchesTeaser: React.FC<IMatchesTeaserProps> = ({
	popularMatches,
	h1,
}) => {
	const { locale } = useRouter();
	const [currentSlide, setCurrentSlide] = useState(0);
	const t = useTranslations('translations');

	const [tournamentMatches, setTournamentMatches] = useState(popularMatches);
	const topLeagues = topLeaguesForLang({ lang: locale });
	const dropDownItems = tranformLeaguesToDropdownItems(topLeagues, locale);
	const [dropdownItem, setDropdownItem] = useState(dropDownItems[0]);
	const numOfGroups = 3;
	const chunkedMatches: any[] = useMemo(
		() => chunkArray([...(tournamentMatches?.slice(0, 9) || [])], numOfGroups),
		[numOfGroups, tournamentMatches],
	);
	const swiperSlidesClasses = clsx(
		styles['matches-teaser__slides'],
		'computed-width-for-swiper-slides',
	);

	const onDropDownChange = dropdownItem => {
		setDropdownItem(dropdownItem);
		getCurrentMatches(dropdownItem).then(dropdownItem => {
			setTournamentMatches(dropdownItem);
		});

		sendAnalytics({
			category: 'dropdown',
			name: 'select',
			label: 'teaser',
			value: `tournament_${dropdownItem.id}`,
		});
	};

	const getCurrentMatches = async dropdownItem => {
		if (dropdownItem?.id) {
			const currentMatchesQueryResult = await client.query({
				query: teaserQueries.getMatches,
				variables: {
					id: dropdownItem?.id,
				},
			});
			const teaser =
				currentMatchesQueryResult?.data?.tournament?.currentSeason?.teaser;
			const popularMatches = [
				...(teaser?.last || []),
				...(teaser?.current || []),
				...(teaser?.next || []),
			];
			return popularMatches;
		}
	};

	const numsOfSlides = chunkedMatches.length;

	useEffect(() => {
		for (let i = 0; i < numsOfSlides; i++) {
			const swiperSlide = document?.getElementById(`swiper-slide__${i}`);
			swiperSlide?.setAttribute('style', `--width: ${100 / numsOfSlides}%;`);
		}
	}, [tournamentMatches]);

	const onShowAllMatchesClick = () => {
		sendAnalytics({
			category: 'to_all_matches',
			name: 'click',
			label: 'teaser',
		});
	};

	const onTableButtonClick = () => {
		sendAnalytics({
			category: 'menu',
			name: 'click',
			label: 'teaser',
			value: `table_${dropdownItem.id}`,
		});
	};

	return (
		!!popularMatches.length && (
			<div className={styles['matches-teaser']}>
				<UiText type={TextType.Heading_1}>{h1 || t('teaser-header')}</UiText>
				<div className={styles['matches-teaser__top']}>
					<div className={styles['matches-teaser__dropdown-wrapper']}>
						<DropdownMenu
							chosenItem={{ text: dropdownItem?.text }}
							dropdownItems={dropDownItems}
							onChange={onDropDownChange}
							isTeaserDropdown
						/>
					</div>

					<div
						className={styles['matches-teaser__tabs']}
						onClick={onTableButtonClick}
					>
						<UiText
							type={TextType.Subtitle_1}
							link={`${TRIBUNA_COM_URL}${locale}/league/${dropdownItem.id}/table/`}
							className={styles['matches-teaser__tab']}
							onClickOnLink
						>
							{t('table')}
						</UiText>
					</div>
				</div>
				<div className={styles['matches-teaser__main']}>
					<div className={styles['matches-teaser__wrapper']}>
						<Swiper
							className={styles['matches-teaser__swiper']}
							modules={[Navigation, Scrollbar, A11y]}
							navigation={{
								prevEl: '.matches-teaser__nav-button--prev',
								nextEl: '.matches-teaser__nav-button--next',
							}}
							setWrapperSize={true}
							spaceBetween={0}
							breakpoints={{
								240: {
									slidesPerView: 1,
								},
								660: {
									slidesPerView: 'auto',
								},
							}}
							onSlideChange={e => setCurrentSlide(e.realIndex)}
						>
							{chunkedMatches.map((matchBlock, index) => (
								<SwiperSlide
									style={
										{
											'--width': `${100 / numsOfSlides}%;`,
										} as React.CSSProperties
									}
									className={swiperSlidesClasses}
									id={`swiper-slide__${index}`}
									key={index}
								>
									{matchBlock.map((match: StatMatch) => (
										<MatchMiniatureTeaser key={match.id} match={match} />
									))}
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div className={styles['matches-teaser__navigation-btn-wrapper']}>
						{!!chunkedMatches.length && (
							<MobileNavigation
								numsOfSlides={numsOfSlides}
								currentSlide={currentSlide}
							/>
						)}
						<UiButton
							className={styles['matches-teaser__button']}
							type={ButtonType.Outlined}
							link={`${TRIBUNA_COM_URL}${locale}/match/`}
							isFullWidth={false}
							onClick={onShowAllMatchesClick}
						>
							{t('all-matches')}
						</UiButton>
					</div>
				</div>
			</div>
		)
	);
};

export default MatchesTeaser;
