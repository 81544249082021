import React from 'react';

import { StatPeriodId } from '@ui-kit/main-api-types';
import {
	NewTextType,
	NewUiText,
} from '@ui-kit/main-frontend-ui-kit/dist/src/components/New-ui-text';
import dayjs from '@ui-kit/main-frontend-ui-kit/dist/src/utils/dayjs';
import { useTranslations } from 'next-intl';
import LazyHydrate from 'react-lazy-hydration';

import styles from './MatchMiniatureTeaser.module.scss';

import ClubIcon from '@assets/svg/TeamLogoIcon.svg';
import ImageComponent from '@components/Image';
import UiText, { TextType } from '@components/Ui-text';
import { MatchStatus, StatMatch } from '@generated-graphql';
import {
	countDifferenceBetweenDates,
	getMatchStatusCaption,
} from '@utils/helpers';

interface IMatchMiniatureTeaserContentProps {
	match: StatMatch;
	language: string;
}

export const MatchMiniatureTeaserContent: React.FC<
	IMatchMiniatureTeaserContentProps
> = ({ match }) => {
	const t = useTranslations('matches');
	const homeTeam = match?.home?.team;
	const awayTeam = match?.away?.team;
	const homeTeamName = homeTeam?.name;
	const awayTeamName = awayTeam?.name;
	const homeTeamLogo = homeTeam?.logo?.main;
	const awayTeamLogo = awayTeam?.logo?.main;
	const matchStatus = match?.matchStatus;
	const hadPenalties = match?.hadPenalties;

	const date = new Date(match?.scheduledAt);
	const { isToday } = countDifferenceBetweenDates(date);
	let formattedDate;
	if (match?.dateOnly) {
		formattedDate = dayjs.tz(date).format('D MMMM');
	} else if (isToday) {
		formattedDate = dayjs(date).format('HH:mm');
	} else {
		formattedDate = dayjs(date).format('DD MMM, H:mm');
	}

	const calculateMatchInfo = () => {
		const baseObject = {
			type: matchStatus,
			scheduledAt: formattedDate,
		};
		const matchScore = `${match?.home?.score}:${match?.away?.score}`;
		const penaltiesScore = `(${match?.home?.penaltyScore}:${match?.away?.penaltyScore})`;
		const score =
			match?.home?.score >= 0 && match?.away?.score >= 0
				? hadPenalties
					? `${matchScore} ${penaltiesScore}`
					: matchScore
				: '';
		switch (matchStatus) {
			case MatchStatus.Closed:
				return {
					...baseObject,
					score,
				};
			case MatchStatus.Live:
				return {
					...baseObject,
					currentMinute: match.currentMinute,
					score: `${score}${
						match?.periodId === StatPeriodId.PenaltyShootout
							? ` (${match?.home?.penaltyScore || 0}:${
									match?.away?.penaltyScore || 0
								})`
							: ''
					}`,
				};
			case MatchStatus.NotStarted:
			case MatchStatus.Postponed:
			case MatchStatus.Delayed:
				return {
					...baseObject,
				};

			default:
				break;
		}
	};

	const matchInfo = calculateMatchInfo();

	const hasCaption = [
		MatchStatus.Cancelled,
		MatchStatus.Postponed,
		//TODO: delayed должен быть переведен как-то по-другому
		MatchStatus.Delayed,
	].includes(matchStatus);

	return (
		<div className={styles['match-miniature-teaser__wrapper']}>
			<div
				className={`${styles['match-miniature-teaser__team']} ${styles['match-miniature-teaser__team--home']}`}
			>
				<UiText
					className={styles['match-miniature-teaser__team-name']}
					type={TextType.Heading_6}
					tagType="span"
				>
					{homeTeamName}
				</UiText>
				<div className={styles['match-miniature-teaser__avatar-wrapper']}>
					{homeTeamLogo ? (
						<ImageComponent
							className={styles['match-miniature-teaser__avatar']}
							width={24}
							height={24}
							src={homeTeamLogo}
						/>
					) : (
						<ClubIcon
							className={styles['match-miniature-teaser__avatar']}
							width={24}
							height={24}
						/>
					)}
				</div>
			</div>
			<div className={styles['match-miniature-teaser__info']}>
				{hasCaption && (
					<UiText
						className={styles['match-miniature-teaser__caption-text']}
						type={TextType.Heading_6}
					>
						{getMatchStatusCaption(matchStatus)}
					</UiText>
				)}
				{matchStatus === MatchStatus.Closed && 'score' in matchInfo && (
					<>
						<NewUiText
							className={styles['match-miniature-teaser__score']}
							type={NewTextType.Overline}
						>
							{matchInfo?.score}
						</NewUiText>
						{hadPenalties && (
							<NewUiText
								className={styles['match-miniature-teaser__caption-text']}
								type={NewTextType.Subtitle_7}
							>
								{t('penalty')}
							</NewUiText>
						)}
					</>
				)}
				{matchStatus === MatchStatus.Live && 'currentMinute' in matchInfo && (
					<span className={styles['match-miniature-teaser__caption-wrapper']}>
						<>
							<UiText
								className={styles['match-miniature-teaser__current-minute']}
								type={TextType.Heading_6}
							>
								{matchInfo?.currentMinute}&apos;
							</UiText>
							<UiText
								className={styles['match-miniature-teaser__score']}
								type={TextType.Overline}
							>
								{matchInfo?.score || 'N/A'}
							</UiText>
						</>
					</span>
				)}
				{matchStatus === MatchStatus.NotStarted && (
					<LazyHydrate whenVisible>
						<UiText
							className={styles['match-miniature-teaser__date']}
							type={TextType.Heading_6}
						>
							{matchInfo?.scheduledAt}
						</UiText>
					</LazyHydrate>
				)}
			</div>
			<div
				className={`${styles['match-miniature-teaser__team']} ${styles['match-miniature-teaser__team--away']}`}
			>
				<div className={styles['match-miniature-teaser__avatar-wrapper']}>
					{awayTeamLogo ? (
						<ImageComponent
							className={styles['match-miniature-teaser__avatar']}
							width={24}
							height={24}
							src={awayTeamLogo}
						/>
					) : (
						<ClubIcon
							className={styles['match-miniature-teaser__avatar']}
							width={24}
							height={24}
						/>
					)}
				</div>
				<UiText
					className={styles['match-miniature-teaser__team-name']}
					type={TextType.Heading_6}
					tagType="span"
				>
					{awayTeamName}
				</UiText>
			</div>
		</div>
	);
};

export default MatchMiniatureTeaserContent;
