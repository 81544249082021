import { gql } from '@apollo/client';

const STAT_MATCH_FRAGMENT = gql`
	fragment statMatch on statMatch {
		id
		hru
		links {
			link
		}
		matchStatus
		scheduledAt
		hadPenalties
		home {
			team {
				id
				tag {
					id
					hru
				}
				name
				logo {
					main
					resize(width: "24", height: "24")
				}
			}
			score
			penaltyScore
		}
		away {
			team {
				id
				tag {
					id
					hru
				}
				name
				logo {
					main
					resize(width: "24", height: "24")
				}
			}
			score
			penaltyScore
		}
		winner
		currentMinute
		dateOnly
	}
`;

const LOCALIZED_STRING_FRAGMENT = gql`
	fragment localizedString on LocalizedString {
		uk
		be
		ru
	}
`;

const TAG_TEAM_EXTRA_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	fragment tagTeamExtra on TagTeamExtra {
		interfaceTitle {
			...localizedString
		}
	}
`;

export default {
	getMatches: gql`
		${STAT_MATCH_FRAGMENT}
		query getMatches($id: ID!) {
			tournament(id: $id) {
				id
				currentSeason {
					teaser(last: 4, next: 9) {
						last {
							...statMatch
						}
						next {
							...statMatch
						}
						current {
							...statMatch
						}
					}
				}
			}
		}
	`,
	getPopularMatches: gql`
		${TAG_TEAM_EXTRA_FRAGMENT}
		${LOCALIZED_STRING_FRAGMENT}
		query getPopularMatches($limit: Int) {
			teaserQueries {
				popularMatches(input: { limit: $limit }) {
					id
					hru
					scheduledAt
					matchStatus
					currentMinute
					hadPenalties
					dateOnly
					home {
						team {
							name
							id
							tag {
								id
								hru
								extra {
									...tagTeamExtra
								}
								logo {
									url
								}
								title {
									...localizedString
								}
							}
							logo {
								main
								resize(width: "24px", height: "24px")
							}
						}
						score
						penaltyScore
					}
					away {
						team {
							name
							id
							tag {
								id
								hru
								extra {
									...tagTeamExtra
								}
								logo {
									url
								}
								title {
									...localizedString
								}
							}
							logo {
								main
								resize(width: "24px", height: "24px")
							}
						}
						score
						penaltyScore
					}
					venue {
						name
						city
						country {
							name
						}
						tag {
							id
							hru
							title {
								...localizedString
							}
						}
					}
					season {
						tournament {
							id
							name
							tag {
								id
								hru
								title {
									...localizedString
								}
							}
						}
					}
					tournamentRound {
						number
					}
				}
			}
		}
	`,
};
