import React from 'react';

import { useRouter } from 'next/router';

import styles from './MatchMiniatureTeaser.module.scss';

import MatchMiniatureTeaserContent from '@components/Match-miniature-teaser/MatchMiniatureTeaserContent';
import { StatMatch } from '@generated-graphql';
import { sendAnalytics, getMatchLink } from '@utils/helpers';

interface IMatchMiniatureTeaserProps {
	match: StatMatch;
	isItMatchCenter?: boolean;
}

export const MatchMiniatureTeaser: React.FC<IMatchMiniatureTeaserProps> = ({
	match,
	isItMatchCenter = false,
}) => {
	const { locale } = useRouter();

	const homeTeam = match?.home?.team;
	const awayTeam = match?.away?.team;

	const matchLink = getMatchLink(match, locale);

	const onMatchClick = e => {
		e.stopPropagation();
		sendAnalytics({
			category: 'to_match',
			name: 'click',
			value: `match_${match?.id}`,
			label: 'teaser',
		});
	};
	return (
		<>
			{!!homeTeam?.id && !!awayTeam?.id ? (
				<a
					href={matchLink}
					className={`${styles['match-miniature-teaser']} ${
						isItMatchCenter ? styles['match-miniature-teaser__match'] : ''
					}`}
					onClick={e => onMatchClick(e)}
				>
					<MatchMiniatureTeaserContent match={match} language={locale} />
				</a>
			) : (
				<div
					className={`${styles['match-miniature-teaser']} ${
						isItMatchCenter ? styles['match-miniature-teaser__match'] : ''
					}`}
				>
					<MatchMiniatureTeaserContent match={match} language={locale} />
				</div>
			)}
		</>
	);
};

export default MatchMiniatureTeaser;
